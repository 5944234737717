import React from 'react';
import PropTypes from 'prop-types';
import HTMLParser from 'html-react-parser';
import Axios from 'axios';
import { format } from 'date-fns/esm';
import clsx from 'clsx';

import instagramLogo from '../../../../../api/instagrem.svg';
import ButtonEditor from '../../ButtonEditor';
import getText from '../../../../helpers/getText';

import styles from './styles.module.css';

const axios = Axios.create({
  withCredentials: false,
});

class InstagramLayout extends React.PureComponent {
  constructor(props) {
    super(props);

    const profileIndex = props.section.data.findIndex((d) => d.type === 'PROFILE/INSTAGRAM');

    const align = props.section.styles.align ? props.section.styles.align : 'Left';

    const imageCount =
      profileIndex > -1 && props.section.data[profileIndex].image_count
        ? props.section.data[profileIndex].image_count
        : 12;
    const accessToken = profileIndex > -1 && props.section.data[profileIndex].access_token;
    const username =
      profileIndex > -1 && props.section.data[profileIndex].username ? props.section.data[profileIndex].username : '';
    const columnAmount =
      profileIndex > -1 && props.section.data[profileIndex].column_amount
        ? Number(props.section.data[profileIndex].column_amount)
        : 6;

    const full = props.section.styles.full || false;

    this.state = {
      isBlocked: false,
      align,
      expires: props.section.data[profileIndex].expires,
      imageCount,
      accessToken,
      username,
      columnAmount,
      full,
      scrollPos: 0,
      topInit: false,
      bottomInit: false,
      last: '',
    };

    this.bottom = React.createRef();
    this.top = React.createRef();
    this.wrapper = React.createRef();
    this.images = React.createRef();
    this.topObserver = null;
    this.bottomObserver = null;
  }

  componentDidMount() {
    this.componentIsMounted = true;
    if (this.state.accessToken) this.getInstagramData(this.state.accessToken);
    if (this.props.layout === 'design3' && this.props.matches) {
      this.determineObserve();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.layout === 'design3' && this.props.matches && prevProps.matches !== this.props.matches) {
      this.determineObserve();
    } else if (this.props.layout === 'design3' && !this.props.matches && prevProps.matches !== this.props.matches) {
      this.disconnect();
    }
  }

  componentWillUnmount() {
    this.componentIsMounted = false;
    this.bottomObserver?.disconnect();
    this.topObserver?.disconnect();
  }

  disconnect = () => {
    this.bottomObserver?.disconnect();
    this.topObserver?.disconnect();
    const elem = document.getElementById(`${this.props.section._id}_texts_target`);
    if (elem) {
      elem.style.removeProperty('position');
      elem.style.removeProperty('top');
      elem.style.removeProperty('width');
    }
  };

  determineObserve = () => {
    if (
      document &&
      document.readyState === 'complete' &&
      this.wrapper &&
      this.wrapper.current &&
      this.wrapper.current.getBoundingClientRect().height - 120 > window.innerHeight
    ) {
      const elem = document.getElementById(`${this.props.section._id}_texts_target`);
      if (elem && this.images && this.images.current) {
        const eRect = elem.getBoundingClientRect();
        const iRect = this.images.current.getBoundingClientRect();
        if (eRect.height <= iRect.height) {
          this.observe();
        } else {
          this.disconnect();
        }
      }
    } else if (document) {
      setTimeout(() => {
        this.determineObserve();
      }, 200);
    }
  };

  observe = () => {
    if (this.props.layout === 'design3' && 'IntersectionObserver' in window && this.props.matches) {
      const elem = this.top.current;
      if (elem) {
        this.topObserver = new IntersectionObserver((entries) => this.handleTopIntersect(entries));
        this.topObserver.observe(elem);
      }
      const elemBottom = this.bottom.current;
      if (elemBottom) {
        this.bottomObserver = new IntersectionObserver((entries) => this.handleBottomIntersect(entries));
        this.bottomObserver.observe(elemBottom);
      }
    }
  };

  getInstagramData = (accessToken) => {
    if ((this.state.instagramData && this.state.instagramData.length > 0) || !accessToken || accessToken.length < 8) {
      if (!this.componentIsMounted) {
        return;
      }

      this.setState({
        isBlocked: false,
        instagramData: [],
      });

      return;
    }

    if (this.state.expires) {
      const url = `https://graph.instagram.com/me/media?access_token=${accessToken}&fields=media_url,media_type,username,thumbnail_url,caption,timestamp`;

      axios
        .get(url)
        .then((response) => {
          let { username } = this.state;

          response.data.data.forEach((d) => {
            if (d.username && d.username.length > 0) {
              // eslint-disable-next-line prefer-destructuring
              username = d.username;
            }
          });

          const instagramData = response.data.data
            .filter((i) => i.media_type === 'IMAGE' || i.media_type === 'VIDEO')
            .slice(0, this.state.imageCount);

          this.setState({
            isBlocked: false,
            instagramData,
            username,
          });
        })
        .catch((e) => {
          console.log(e);

          this.setState({
            isBlocked: true,
            instagramData: [],
          });
        });
    } else {
      // backwards compatibility (only useful until march 31st 2020):

      const url = `https://api.instagram.com/v1/users/self/media/recent/?access_token=${accessToken}&count=${this.state.imageCount}`;

      if ((this.state.instagramData && this.state.instagramData.length > 0) || !accessToken || accessToken.length < 8) {
        if (!this.componentIsMounted) return;

        this.setState({
          isBlocked: false,
          instagramData: [],
        });

        return;
      }

      axios
        .get(url)
        .then((response) => {
          this.setState({
            isBlocked: false,
            instagramData: response.data.data,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            isBlocked: true,
            instagramData: [],
          });
        });
    }
  };

  handleImageError = (index) => {
    const newInstaData = this.state.instagramData;

    if (!newInstaData) return;

    newInstaData[index].hidden = true;
    this.setState({
      instagramData: newInstaData,
    });
  };

  handleBottomIntersect = (entries) => {
    if (!this.state.bottomInit) {
      this.setState({ bottomInit: true });
    } else if (
      entries &&
      entries[0] &&
      entries[0].isIntersecting &&
      !this.props.mobileView &&
      this.state.instagramData.length > 0
    ) {
      // Bottom is intersecting
      this.setState({ scrollPos: entries[0].boundingClientRect.y, last: 'BOTTOMDOWN' });
      const elem = document.getElementById(`${this.props.section._id}_texts_target`);
      if (elem) {
        const parentRect = elem.parentNode.getBoundingClientRect();
        const top = parentRect.height - window.innerHeight;
        elem.style.position = 'absolute';
        elem.style.top = `${top}px`;
      }
    } else if (
      entries &&
      entries[0] &&
      !entries[0].isIntersecting &&
      !this.props.mobileView &&
      this.state.instagramData.length > 0
    ) {
      // Bottom is not intersecting
      const scrollPos = entries[0].boundingClientRect.y;
      const up = scrollPos > this.state.scrollPos;
      if (up && this.state.last !== 'TOPUP') {
        this.setState({ scrollPos, last: 'BOTTOMUP' });
        // Scroll direction is up
        const elem = document.getElementById(`${this.props.section._id}_texts_target`);
        if (elem && elem.style.position === 'absolute') {
          elem.style.position = 'fixed';
          elem.style.top = '20px';
          elem.style.width = `${elem.parentNode.clientWidth - 30}px`;
        }
      }
    }
  };

  handleTopIntersect = (entries) => {
    if (!this.state.topInit) {
      this.setState({ topInit: true });
    } else if (
      entries &&
      entries[0] &&
      entries[0].isIntersecting &&
      !this.props.mobileView &&
      this.state.instagramData.length > 0
    ) {
      // Top is intersecting
      this.setState({ scrollPos: entries[0].boundingClientRect.y, last: 'TOPUP' });
      const elem = document.getElementById(`${this.props.section._id}_texts_target`);
      if (elem && (elem.style.position === 'fixed' || elem.style.position === 'absolute')) {
        elem.style.removeProperty('position');
        elem.style.removeProperty('top');
        elem.style.removeProperty('width');
      }
    } else if (
      entries &&
      entries[0] &&
      !entries[0].isIntersecting &&
      !this.props.mobileView &&
      this.state.instagramData.length > 0 &&
      this.state.last !== 'BOTTOMDOWN'
    ) {
      // Top is not intersecting
      const scrollPos = entries[0].boundingClientRect.y;
      const up = scrollPos > this.state.scrollPos;
      const elem = document.getElementById(`${this.props.section._id}_texts_target`);
      if (elem && elem.style.position !== 'fixed' && !up) {
        // Scroll direction is down
        this.setState({ scrollPos, last: 'TOPDOWN' });
        elem.style.position = 'fixed';
        elem.style.top = '20px';
        elem.style.width = `${elem.parentNode.clientWidth - 30}px`;
      }
    }
  };

  render() {
    const { section, themeData, layout } = this.props;
    const { instagramData, username, columnAmount, accessToken, align, full } = this.state;
    const colGridWidth = 12 / columnAmount;
    const bootstrapGridStyle = `col-12 col-sm-${colGridWidth}`;
    const alignStyle = `align${align}`;
    const images = [];

    let texts = [];
    let btn;

    section.data.forEach((elem, i) => {
      if (elem.active && (elem.type === 'HEADINGS/HEADING-TWO' || elem.type === 'PARAGRAPH/PARAGRAPH')) {
        let text;
        if (elem.type === 'HEADINGS/HEADING-TWO') {
          text = `<h2>${elem.text}</h2>`;
        } else {
          text = `<span>${elem.text}</span>`;
        }

        texts.push(
          <div key={`${section._id}_layout_${i}`} className={`col-12 ${styles.text} ${styles[alignStyle]}`}>
            {HTMLParser(text)}
          </div>,
        );
      } else if (elem.active && elem.type === 'BUTTONS/BUTTON_SECONDARY') {
        const link = {
          linkData: {
            link: `https://instagram.com/${username}`,
          },
          openLinkInNewTab: true,
          type: 'EXTERNAL',
        };

        const newData = elem.content;
        newData.linkObj = link;

        btn = (
          <div
            key={`${section._id}_layout_${i}`}
            className={styles[`instagramBtnWrapper${layout === 'design3' ? `${align}` : ''}`]}
          >
            <ButtonEditor
              themeData={themeData}
              button="Secondary"
              data={newData}
              buttons={this.props.buttons}
              image={instagramLogo}
            />
          </div>
        );
      }
    });

    if (layout === 'design3') {
      texts = (
        <div className={`col-12 col-md-4 ${styles.leftWrapper}`}>
          <div id={`${section._id}_texts_target`}>
            {texts.map((text, i) => (
              <div key={`${section._id}_text_${i}`} className={styles.wrapper}>
                {text}
              </div>
            ))}
            {btn}
          </div>
        </div>
      );
      btn = undefined;
    }

    if (instagramData && accessToken) {
      instagramData.forEach((item, index) => {
        if (item.hidden) return;

        let element;
        const className = layout === 'design3' ? '' : styles.instagramImg;

        if (item.media_type === 'IMAGE') {
          const src = item.media_url || (item.images && item.images.standard_resolution);
          const alt = (item.caption && item.caption.text) || 'Instagram image';

          if (!src) return;

          element = <img alt={alt} className={className} src={src} draggable="false" />;
        } else if (item.media_type === 'VIDEO') {
          element = <video controls className={className} src={item.media_url} poster={item.thumbnail_url} />;
        }

        if (!element) return;

        if (layout === 'gutters' || layout === 'noGutters') {
          images.push(
            <div
              key={`${section._id}_section_1_instagramFeedImage_${index}`}
              className={`${bootstrapGridStyle} ${
                styles[`instagram${layout === 'noGutters' ? 'No' : ''}GuttersImage`]
              }`}
              onError={() => this.handleImageError(index)}
            >
              <div className={styles.instagramImageWrapper}>{element}</div>
            </div>,
          );
        } else if (layout === 'design3') {
          images.push(
            <div
              key={`${section._id}_section_1_instagramFeedImage_${index}`}
              className={styles.imgWrapper}
              onError={() => this.handleImageError(index)}
            >
              {element}
              <div className={styles.caption}>
                <span>{item.caption}</span>
              </div>
              <div>
                <span>{item.timestamp && format(new Date(item.timestamp), 'd.M.yyyy HH:mm')}</span>
              </div>
            </div>,
          );
        }
      });
    }

    return (
      <div
        ref={this.wrapper}
        className={`${full && layout !== 'design3' ? '' : 'container'} ${
          styles[`instagramContainer${full ? 'Full' : ''}`]
        }`}
      >
        <div ref={this.top} />
        <div className="row">
          {texts}
          <div className={`col-12 ${layout === 'design3' ? 'col-md-8' : ''} ${layout === 'design3' ? '' : 'content'}`}>
            <div ref={this.images} className={`row ${styles.wrapper} ${styles[alignStyle]}`}>
              {this.state.isBlocked ? (
                <div className={clsx('container', styles.errorContainer)}>
                  {getText('errorHappenedCheckSecuritySettings', this.props.siteLanguage)}
                </div>
              ) : (
                <>
                  {btn}
                  {images}
                </>
              )}
            </div>
          </div>
        </div>
        <div ref={this.bottom} />
      </div>
    );
  }
}

InstagramLayout.propTypes = {
  siteLanguage: PropTypes.string,
  align: PropTypes.string,
  section: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  layout: PropTypes.string,
  full: PropTypes.string,
};

export default InstagramLayout;
